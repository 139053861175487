<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Sub Category Product</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">Product Category (*)</span>
        <v-select
          label="name"
          :options="productCategoryOptions"
          :reduce="category => category.id"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="payload.category_product_id"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("category_product_id")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mb-3">
        <vs-input
          class="w-full"
          icon-pack="feather"
          icon="icon-box"
          icon-no-border
          label="Sub Category Product Name (*)"
          v-model="payload.name"
        />
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("name")
        }}</span>
      </vs-col>

      <vs-col vs-w="12" class="mt-3">
        <div class="vx-col w-full" @click.prevent="storeData">
          <vs-button class="mr-3 mb-2">Submit</vs-button>
        </div>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Store Module
import moduleSubCategoryProductManagement from "@/store/sub-category-product-management/moduleSubCategoryProductManagement.js";

export default {
  name: "SubCategoryProductAdd",

  metaInfo: {
    title: "Add Sub Category Product",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true
    }
  },

  components: {
    "v-select": vSelect
  },

  data: () => ({
    payload: {
      category_product_id: '',
      name: ""
    },

    productCategoryOptions: []
  }),

  methods: {
    getProductCategories () {
      axios.get(`category-product`)
           .then(({data: res}) => {
             this.productCategoryOptions = res.data.map(category => {
               return {
                 id: category.id,
                 name: category.name
               }
             })
           })
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("category_product_id", this.payload.category_product_id);
      formData.set("name", this.payload.name);

      this.$store
        .dispatch(
          "subCategoryProductManagement/storeSubCategoryProduct",
          formData
        )
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success"
          });

          this.$router.push("/sub-category-products");
        })
        .catch(err => {
          this.$catchErrorResponse(err);
        });
    }
  },

  created() {
    if (!moduleSubCategoryProductManagement.isRegistered) {
      this.$store.registerModule(
        "subCategoryProductManagement",
        moduleSubCategoryProductManagement
      );
      moduleSubCategoryProductManagement.isRegistered = true;
    }

    this.getProductCategories()
  }
};
</script>

<style></style>
